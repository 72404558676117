<template lang="pug">
  ToggleButton.app-toggle(
    :sync="true"
    v-bind="$props"
    v-on="$listeners"
  )
</template>

<script>
  // components
  import { ToggleButton } from "vue-js-toggle-button"

  export default {
    components: {
      ToggleButton
    },

    props: {
      value: {
        type: Boolean,
        default: false
      },
      name: {
        type: String
      },
      color: {
        type: Object,
        default() {
          return {
            checked: "#5965e7", // $default-purple
            unchecked: "#e9eff4", // $border-element-color
            disabled: "#8893a9" // $default-gray
          }
        }
      },
      disabled: {
        type: Boolean,
        default: false
      }
    }
  }
</script>

<style lang="sass">
  @import "@/assets/styles/variables.sass"

  .app-toggle
    margin: 0

    &.toggled
      .v-switch-button
        transform: translate3d(16.5px, 1.5px, 0px) !important

    .v-switch-core
      height: 18px !important
      width: 33px !important

    .v-switch-button
      border: 1px solid $default-gray-medium
      box-shadow: 0 4px 4px 0 transparentize($default-black, .9)
      height: 15px !important
      transform: translate3d(1.5px, 1.5px, 0px) !important
      width: 15px !important
</style>
